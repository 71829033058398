/**
 * 网络请求配置
 */
import axios from "axios";
import md5 from "js-md5";

axios.defaults.timeout = 100000;
// axios.defaults.baseURL = "http://kkgj28-api.com";   // 旧正式环境
// axios.defaults.baseURL = "http://127.0.0.1:5000";   // 本地环境
axios.defaults.baseURL = "https://kkaakj.com";   // KK正式环境
// axios.defaults.baseURL = "https://haina9.com";   // HaiNa正式环境

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  (config) => {
    if (!config.data) {
      config.data = {};
    }
    config.data = JSON.stringify(config.data);
    //请求头加密参数
    const headerParams = {
      AppId: "BB3647441FFA4B5DB4E64A29B53CE525",
      Secret: "C5ABA9E202D94C43A3CA66002BF96FAF",
      Authorization:
        "E6D90DDBC70C4F4EA3C312B6FCB473C8BA9E5A55C3F9BBB0202DD96BFA5BF2AF",
      RandomNumber: parseInt(999999 * Math.random()) + "",
    };
    const { AppId, Secret, Authorization, RandomNumber } = headerParams;

    config.headers = {
      "Content-Type": "application/json",
      AppId: AppId,
      Secret: Secret,
      Authorization: Authorization,
      RandomNumber: RandomNumber,
      Sign: md5(
        AppId + "&" + Secret + "&" + Authorization + "&" + RandomNumber
      ),
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  (response) => {
    if (response.data.errCode === 2) {
    }
    return response;
  },
  (error) => {
  }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((response) => {
        landing(url, params, response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        //关闭进度条
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        msag(err);
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        msag(err);
        reject(err);
      }
    );
  });
}

//统一接口处理，返回数据
export default function fetch(fetch, url, param) {
  return new Promise((resolve, reject) => {
    switch (fetch) {
      case "get":
        get(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
        break;
      case "post":
        post(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
        break;
      default:
        get(url, param)
            .then(function (response) {
              resolve(response);
            })
            .catch(function (error) {
              reject(error);
            });
        break;
    }
  });
}

//失败提示
function msag(err) {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        alert(err.response.data.error.details);
        break;
      case 401:
        alert("未授权，请登录");
        break;

      case 403:
        alert("拒绝访问");
        break;

      case 404:
        alert("请求地址出错");
        break;

      case 408:
        alert("请求超时");
        break;

      case 500:
        alert("服务器内部错误");
        break;

      case 501:
        alert("服务未实现");
        break;

      case 502:
        alert("网关错误");
        break;

      case 503:
        alert("服务不可用");
        break;

      case 504:
        alert("网关超时");
        break;

      case 505:
        alert("HTTP版本不受支持");
        break;
      default:
    }
  }
}

/**
 * 查看返回的数据
 * @param url
 * @param params
 * @param data
 */
function landing(url, params, data) {
  if (data.code === -1) {
  }
}
